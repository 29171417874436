@font-face {
  font-family: "Open Sans";
  src: url("./resources/fonts/OpenSans-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Open Sans";
  src: url("./resources/fonts/OpenSans-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Open Sans";
  src: url("./resources/fonts/OpenSans-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Open Sans";
  src: url("./resources/fonts/OpenSans-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Roboto";
  src: url("./resources/fonts/Roboto-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Roboto";
  src: url("./resources/fonts/Roboto-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Roboto";
  src: url("./resources/fonts/Roboto-Medium.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Roboto";
  src: url("./resources/fonts/Roboto-Bold.ttf");
  font-weight: 700;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
